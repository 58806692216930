<template>
  <div class='card card-top card-top-success'>
    <div class='card-header'>
      <vb-headers-card-header :data="{ title: `${title} (${statistic.total} ${prefix})` }" />
    </div>
    <div class='card-body'>
      <div>
        <b>Günlük:</b> {{ statistic.daily }} {{ prefix }}
      </div>
      <div>
        <b>Haftalık:</b> {{ statistic.weekly }} {{ prefix }}
      </div>
      <div>
        <b>Aylık:</b> {{ statistic.monthly }} {{ prefix }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StatisticCard',
  props: {
    title: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: true,
    },
    statistic: {
      type: Object,
      default: () => {
      },
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
