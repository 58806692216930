export function writeActivityType(activityType) {
  switch (activityType) {
    case 'StartedToBroadcast':
      return 'Yayına Başladı'
    case 'JoinedToBroadcast':
      return 'Yayına Katıldı'
    case 'TrainingWatched':
      return 'Antrenman İzlendi'
    case 'BoughtPackageCourse':
      return 'Ders Paketi Satın Alındı'
    default:
      return ''
  }
}
