<template>
  <a-modal title='Antrenör İstatistikleri' v-model:visible='isVisible' centered :after-close='onClose'>
    <template #footer>
      <a-button key='back' type='primary' @click='onClose'>Kapat</a-button>
    </template>
    <div v-if='statistics'>

      <statistic-card prefix='TL' title='Kazançlar' :statistic='statistics.personalTrainerEarnings' />
      <statistic-card prefix='Canlı Yayın' title='Canlı Yayın Sayısı' :statistic='statistics.liveStreamCount' />
      <statistic-card prefix='Dakika' title='Canlı Yayın Süresi' :statistic='statistics.liveStreamDuration' />
      <statistic-card prefix='Kalori' title='Yayınlarda Yakılan Kalori' :statistic='statistics.burntCalories' />
      <statistic-card prefix='Kişi' title='Yayınlara Katılan Kişi' :statistic='statistics.uniqueUsersJoinedToBroadcasts' />
      <statistic-card prefix='Kişi' title='Referans Sayısı' :statistic='statistics.referenceCount' />

    </div>
    <div v-else>
      <a-spin />
    </div>
  </a-modal>
</template>

<script>
import { defineComponent, watch } from 'vue'
import { PersonalTrainer, PersonalTrainerAdministration } from '../../services/api-service'
import StatisticCard from '@/components/statisticCard'

export default defineComponent({
  name: 'EarningsModal',
  components: { StatisticCard },
  props: {
    selected: {
      type: Number,
      required: false,
      default: undefined,
    },
    visible: {
      type: Boolean,
      default: false,
      required: true,
    },
    onClose: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    watch(() => this.visible, (value) => {
      this.isVisible = value

    })

    watch(() => this.selected, (value) => {
      if (typeof value !== 'undefined') {
        this.loadEarnings(value)
      } else {
        this.statistics = undefined
      }
    })

    return {
      isVisible: false,
      loading: false,
      statistics: undefined,
    }
  },
  methods: {
    async loadEarnings(id) {
      try {
        const { data } = await PersonalTrainerAdministration.getStatisticsOfPersonalTrainerForAdministration(id)
        this.statistics = data
      } catch {
        this.statistics = undefined
      }
    },
  },
})
</script>
