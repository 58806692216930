<template>
  <div>
    <div class='row'>
      <div class='col-12'>
        <div class='card card-top card-top-primary'>
          <div class='card-header d-flex justify-content-between align-items-center'>
            <vb-headers-card-header :data="{ title: 'Antrenörler' }" />
            <router-link to='/yeni-antrenor-ekle' type='button' class='btn btn-primary btn-with-addon text-nowrap'>
              <span class='btn-addon'>
                <i class='btn-addon-icon fe fe-plus-circle'></i>
              </span>
              Yeni Antrenör Ekle
            </router-link>
          </div>
          <div class='card-body'>
            <div class='table-responsive text-nowrap'>
              <a-table
                :locale='{emptyText: "Henüz Antrenör Yok"}'
                :loading='loading'
                :columns='columns'
                :data-source='personalTrainers'
                :pagination='false'
                :row-key='(record) => record.id'>
                <template #avatar='{ record }'>
                  <div class='vb__utils__avatar'>
                    <a-image
                      :src='record.avatar.avatar4X'
                      alt='User'
                      fallback='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png'
                    />
                  </div>
                </template>
                <template #rate='{ record }'>
                  <div>{{ roundHalf(record.rate) }}</div>
                </template>
                <template #ptOrder='{ record }'>
                  <div :class='record.ptOrder !== null ? "" : "font-italic text-gray-3"'>{{ record.ptOrder ?? "Belirtilmemiş" }}</div>
                </template>
                <template #statistics='{ record }'>
                  <a-button class='btn btn-success mr-1' @click='() => showEarningPt = record.metadataId'>
                    <i class='fe fe-eye text white' />
                  </a-button>
                </template>
                <template #operation='{ record }'>
                  <a-button class='btn btn-primary mr-1' @click='showEditPtModal(record.metadataId)' :loading='modalLoading === record.metadataId' style='width: 46px'>
                    <i class='fe fe-settings text-white' v-if='modalLoading !== record.metadataId' />
                  </a-button>
                </template>
              </a-table>
            </div>

            <div class='clearfix mt-2'>
              <a-pagination
                class='float-right'
                @change='loadPersonalTrainers'
                v-model:page-size='pagination.recordsPerPage'
                v-model:current='pagination.currentPage'
                :total='pagination.totalRecords'
                :show-total='total => `Toplam ${total} kullanıcı`'
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <a-modal
      v-model:visible='editPtVisible'
      width='80%'
      title='Antrenör Bilgilerini Düzenle'
      centered
    >
      <template #footer>
        <a-button key='back' type='primary' @click='() => editPtVisible = false'>Kapat</a-button>
      </template>

      <div class='row'>
        <div class='col-lg-6 col-md-12'>

          <div class='row'>
            <div class='col-lg-12'>
              <div class='card card-top card-top-primary'>
                <div class='card-header'>
                  <vb-headers-card-header :data="{ title: 'Antrenör Bilgilerini Düzenle' }" />
                </div>
                <div class='card-body'>

                  <image-resizer
                    :loading='loading'
                    :on-complete='uploadPhoto'
                    :max-size='904'
                    :initial-image='ptAvatar'
                    make-square
                    :error-text='"Fotoğraf en az 900x900 piksel ölçülerinde kare olmalıdır."'
                  />

                  <a-form
                    label-align='left'
                    layout='vertical'
                  >

                    <a-form-item label='Adı' name='firstname'>
                      <a-input v-model:value='ptFirstName' />

                      <a-button v-if='showFirstNameSaveButton' @click='updatePtName'
                                class='btn btn-success mt-2'>Güncelle
                      </a-button>
                    </a-form-item>

                    <a-form-item label='Soyadı' name='lastname'>
                      <a-input v-model:value='ptLastName' />

                      <a-button v-if='showLastNameSaveButton' @click='updatePtSurName'
                                class='btn btn-success mt-2'>Güncelle
                      </a-button>
                    </a-form-item>

                    <a-form-item label='Açıklaması' name='description'>
                      <a-textarea :auto-size='{ minRows: 4, maxRows: 4 }' v-model:value='ptDescription' show-count :maxlength='255' />

                      <a-button v-if='showDescriptionSaveButton' @click='updatePtDescription'
                                class='btn btn-success mt-2'>Güncelle
                      </a-button>
                    </a-form-item>

                    <a-form-item label='Mail Adresi' name='mail' @keypress='validateEmail(ptMail)'>
                      <a-input v-model:value='ptMail' />
                      <a-tag color='error' v-if='ptMail !== "" & !validateEmail(ptMail)' class='mt-1'>
                        <template #icon>
                          <i class='fe fe-alert-circle' />
                        </template>
                        Geçerli Bir Mail Adresi Giriniz
                      </a-tag>

                      <a-button :disabled='!validateEditPtMail' v-if='showMailSaveButton && validateEditPtMail' @click='updatePtMail'
                                class='btn btn-success mt-2'>Güncelle
                      </a-button>
                    </a-form-item>


                    <a-form-item label='Şifre' name='password'>
                      <a-input-password v-model:value='password' />
                      <a-tag color='error' v-if='password !== "" && (password.length < 6 || password.length > 64)'
                             class='mt-1'>
                        <template #icon>
                          <i class='fe fe-alert-circle' />
                        </template>
                        Şifre Minimum 6, Maximum 64 Karakter olmalıdır
                      </a-tag>

                      <a-button :disabled='!validateEditPtPassword' v-if='showPasswordSaveButton && validateEditPtPassword'
                                @click='updatePtPassword' class='btn btn-success mt-2'>
                        Güncelle
                      </a-button>
                    </a-form-item>

                    <a-form-item label='Abonelik Komisyon Oranı' name='premium'>
                      <a-input addon-before='%' v-model:value='ptCommissionRate' v-maska.raw='"###"' />
                      <a-tag color='error' v-if='ptCommissionRate === "" || parseInt(ptCommissionRate) < 0 || parseInt(ptCommissionRate) > 100'
                             class='mt-1'>
                        <template #icon>
                          <i class='fe fe-alert-circle' />
                        </template>
                        Minimum 0%, Maximum 100% olmalıdır
                      </a-tag>

                      <a-button :disabled='!validateEditPtCommissionRate' v-if='showCommissionRateSaveButton && validateEditPtCommissionRate'
                                @click='updateCommissionRate' class='btn btn-success mt-2'>
                        Güncelle
                      </a-button>
                    </a-form-item>

                    <a-form-item label='Ders Paketi Komisyon Oranı' name='premium'>
                      <a-input addon-before='%' v-model:value='ptPackageCourseCommissionRate' v-maska.raw='"###"' />
                      <a-tag color='error'
                             v-if='ptPackageCourseCommissionRate === "" || parseInt(ptPackageCourseCommissionRate) < 0 || parseInt(ptPackageCourseCommissionRate) > 100'
                             class='mt-1'>
                        <template #icon>
                          <i class='fe fe-alert-circle' />
                        </template>
                        Minimum 0%, Maximum 100% olmalıdır
                      </a-tag>

                      <a-button :disabled='!validateEditPtPackageCourseCommissionRate' v-if='showPackageCourseCommissionRateSaveButton && validateEditPtPackageCourseCommissionRate'
                                @click='updatePackageCourseCommissionRate' class='btn btn-success mt-2'>
                        Güncelle
                      </a-button>
                    </a-form-item>

                    <a-form-item label='Sıralaması' name='ptOrder'>
                      <a-input v-maska.raw="'########'" placeholder='Sıralaması' v-model:value='ptOrder' />

                      <a-button v-if='showPtOrderSaveButton' @click='updatePtOrder'
                                class='btn btn-success mt-2'>Güncelle
                      </a-button>
                    </a-form-item>
                  </a-form>
                </div>
              </div>
            </div>
          </div>
          <div class='row'>
            <div class='col-lg-12'>
              <div class='card card-top card-top-primary'>
                <div class='card-header'>
                  <vb-headers-card-header :data="{ title: 'Hesap İşlemleri' }" />
                </div>
                <div class='card-body'>
                  <a-popconfirm
                    title='Antrenörü Silmek İstediğinize Emin Misiniz?'
                    ok-text='Evet'
                    cancel-text='Hayır'
                    @confirm='confirmDelete(currentPt.metadataId)'
                  >
                    <a-button class='btn btn-danger'>Hesabı Kalıcı Olarak Sil</a-button>
                  </a-popconfirm>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class='col-lg-6 col-md-12'>
          <div class='card card-top card-top-primary'>
            <div class='card-header'>
              <vb-headers-card-header :data="{ title: 'Antrenör Aktiviteleri' }" />
            </div>
            <div class='card-body overflow-auto card-max-height'>
              <div class='table-responsive text-nowrap'>
                <a-table :columns='columnsActivity'
                         :data-source='currentPtActivities'
                         :pagination=false
                         :row-key='(record) => record.id + "activity"'
                         :loading='loading'
                         :locale='{emptyText: "Henüz Aktivite Yok"}'
                >
                  <template #id='{ record }'>
                    <span class='font-weight-bold'>{{ record.id }}</span>
                  </template>
                  <template #activityType='{ record }'>
                    <span>{{ writeActivityType(record.activityType) }}</span>
                  </template>
                  <template #happenedAt='{ record }'>
                    <span>{{ moment(record.happenedAt).format('LL, HH:mm') }}</span>
                  </template>
                </a-table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </a-modal>

    <earnings-modal :visible='typeof showEarningPt !== "undefined"' :selected='showEarningPt' :on-close='() => showEarningPt = undefined' />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { notification } from 'ant-design-vue'
import { PersonalTrainerAdministration } from '../../services/api-service'
import { writeActivityType } from '@/utils/text-helper'
import { roundHalf } from '@/utils/number'
import EarningsModal from '@/views/antrenorler/earningsModal'
import { validateEmail } from '../../utils/email'

import moment from 'moment'
import trLocale from 'moment/locale/tr'
import locale from 'ant-design-vue/es/date-picker/locale/tr_TR'
import tr_TR from 'ant-design-vue/es/locale-provider/tr_TR'

moment.updateLocale('tr', trLocale)

const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
  },
  {
    title: 'Avatar',
    dataIndex: 'avatar.avatar1X',
    class: 'w-auto',
    slots: { customRender: 'avatar' },
  },
  {
    title: 'Adı',
    dataIndex: 'name',
  },
  {
    title: 'Soyadı',
    dataIndex: 'surname',
  },
  {
    title: 'Derece',
    dataIndex: 'rate',
    slots: { customRender: 'rate' },
  },
  {
    title: 'Sıralama',
    dataIndex: 'ptOrder',
    slots: { customRender: 'ptOrder' },
  },
  {
    title: 'Email',
    dataIndex: 'email',
    class: 'w-100',
  },
  {
    title: 'İstatistikler',
    dataIndex: 'statistics',
    className: 'text-center',
    slots: { customRender: 'statistics' },
  },
  {
    title: 'Yönet',
    dataIndex: 'operation',
    className: 'text-center',
    slots: { customRender: 'operation' },
  },
]
const columnsActivity = [
  {
    title: 'ID',
    dataIndex: 'id',
    slots: { customRender: 'id' },
  },
  {
    title: 'Aktivite Türü',
    dataIndex: 'activityType',
    slots: { customRender: 'activityType' },
  },
  {
    title: 'Saati',
    dataIndex: 'happenedAt',
    slots: { customRender: 'happenedAt' },
  },
]

export default defineComponent({
  name: 'PTYonetimi',
  components: { EarningsModal },
  data() {
    const loading = ref(false)

    tr_TR.DatePicker.lang.ok = 'Tamam'

    return {
      tr_TR,
      locale,
      moment,
      roundHalf,
      writeActivityType,
      loading,
      validateEmail,
      columnsActivity,
      columns,
      newPtVisible: false,
      modalLoading: null,
      editPtVisible: false,
      showEarningPt: undefined,
      ptAvatar: '',
      dateFormat: 'YYYY-MM-DD',
      personalTrainers: [],
      ptMetaData: '',
      ptFirstName: '',
      ptFirstNameFromServer: '',
      ptLastName: '',
      ptLastNameFromServer: '',
      ptMail: '',
      ptMailFromServer: '',
      ptCommissionRate: '',
      ptCommissionRateFromServer: '',
      ptPackageCourseCommissionRate: '',
      ptPackageCourseCommissionRateFromServer: '',
      ptDescription: '',
      ptDescriptionFromServer: '',
      ptOrder: '',
      ptOrderFromServer: '',
      password: '',
      birthDate: '',
      pagination: { totalPages: 1, currentPage: 1, totalRecords: 0, recordsPerPage: 1 },
      currentPtActivities: [],
      currentPt: {},
      imageUrl: null,
    }
  },
  computed: {
    showPasswordSaveButton() {
      return this.password.length > 1
    },
    showMailSaveButton() {
      return this.ptMail !== '' && (this.ptMail !== this.ptMailFromServer)
    },
    showFirstNameSaveButton() {
      return this.ptFirstName !== '' && (this.ptFirstName !== this.ptFirstNameFromServer)
    },
    showLastNameSaveButton() {
      return this.ptLastName !== '' && (this.ptLastName !== this.ptLastNameFromServer)
    },
    showDescriptionSaveButton() {
      return this.ptDescription !== '' && (this.ptDescription !== this.ptDescriptionFromServer)
    },
    showPtOrderSaveButton() {
      return this.ptOrder !== '' && (this.ptOrder !== this.ptOrderFromServer)
    },
    showCommissionRateSaveButton() {
      return this.ptCommissionRate !== '' && (parseInt(this.ptCommissionRate) !== parseInt(this.ptCommissionRateFromServer))
    },
    showPackageCourseCommissionRateSaveButton() {
      return this.ptPackageCourseCommissionRate !== '' && (parseInt(this.ptPackageCourseCommissionRate) !== parseInt(this.ptPackageCourseCommissionRateFromServer))
    },
    validateEditPtMail() {
      return (
        this.validateEmail(this.ptMail) &&
        this.ptMail !== ''
      )
    },
    validateEditPtCommissionRate() {
      return (
        (parseInt(this.ptCommissionRate) >= 0 && parseInt(this.ptCommissionRate) <= 100)
      )
    },
    validateEditPtPackageCourseCommissionRate() {
      return (
        (parseInt(this.ptPackageCourseCommissionRate) >= 0 && parseInt(this.ptPackageCourseCommissionRate) <= 100)
      )
    },
    validateEditPtPassword() {
      return (
        (this.password.length >= 6 && this.password.length <= 64)
      )
    },
  },
  mounted() {
    this.loadPersonalTrainers()
  },
  methods: {
    beforeUpload(file) {
      this.newPtAvatarBase64 = file
    },
    async showEditPtModal(id) {
      this.modalLoading = id
      try {
        const { data } = await PersonalTrainerAdministration.getPersonalTrainerDetailsForAdministration(id)
        if (data) {
          this.currentPt = data
          this.ptAvatar = data.avatar.avatar4X
          this.ptFirstName = this.currentPt.name
          this.ptFirstNameFromServer = this.currentPt.name
          this.ptLastName = this.currentPt.surname
          this.ptLastNameFromServer = this.currentPt.surname
          this.ptMail = this.currentPt.email
          this.ptMailFromServer = this.currentPt.email
          this.ptCommissionRate = this.currentPt.commissionRate
          this.ptCommissionRateFromServer = this.currentPt.commissionRate
          this.ptPackageCourseCommissionRate = this.currentPt.packageCourseCommissionRate
          this.ptPackageCourseCommissionRateFromServer = this.currentPt.packageCourseCommissionRate
          this.ptDescription = this.currentPt.description
          this.ptDescriptionFromServer = this.currentPt.description
          this.ptOrderFromServer = this.currentPt.ptOrder
          this.ptOrder = this.currentPt.ptOrder
        }
      } catch {
        this.modalLoading = null
        return false
      }

      try {
        const { data } = await PersonalTrainerAdministration.listPersonalTrainerActivitiesForAdministration(this.currentPt.metadataId, 1)
        if (data.activities) {
          this.currentPtActivities = data.activities
        }
      } catch {
      }

      this.modalLoading = null
      this.editPtVisible = true
    },
    async uploadPhoto(file) {
      this.loading = true

      try {
        const { data } = await PersonalTrainerAdministration.updateAvatarOfPersonalTrainer({
          personalTrainerMetadataId: this.currentPt.metadataId,
          newAvatar: file,
        })

        this.ptAvatar = data.avatars.avatar1X

        this.currentPt.avatar = data.avatars
        const indexOldElement = this.personalTrainers.findIndex(({ id }) => id === this.currentPt.id)
        this.personalTrainers = Object.assign([...this.personalTrainers], { [indexOldElement]: this.currentPt })

        notification.success({
          message: 'Resim başarıyla değiştirildi',
        })
      } catch {

      }

      this.loading = false
    },
    async updatePtName() {
      try {
        await PersonalTrainerAdministration.updateNameOfPersonalTrainer({
          name: this.ptFirstName,
          personalTrainerMetadataId: this.currentPt.metadataId,
        })
        this.ptFirstNameFromServer = this.ptFirstName

        this.currentPt.name = this.ptFirstName
        const indexOldElement = this.personalTrainers.findIndex(({ id }) => id === this.currentPt.id)
        this.personalTrainers = Object.assign([...this.personalTrainers], { [indexOldElement]: this.currentPt })

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'İsim başarıyla güncellendi.',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updatePtSurName() {
      try {
        await PersonalTrainerAdministration.updateSurNameOfPersonalTrainer({
          surName: this.ptLastName,
          personalTrainerMetadataId: this.currentPt.metadataId,
        })
        this.ptLastNameFromServer = this.ptLastName

        this.currentPt.surname = this.ptLastName
        const indexOldElement = this.personalTrainers.findIndex(({ id }) => id === this.currentPt.id)
        this.personalTrainers = Object.assign([...this.personalTrainers], { [indexOldElement]: this.currentPt })

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Soyisim başarıyla güncellendi.',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updatePtMail() {
      try {
        await PersonalTrainerAdministration.updateEmailOfPersonalTrainer({
          newEmail: this.ptMail,
          personalTrainerMetadataId: this.currentPt.metadataId,
        })
        this.ptMailFromServer = this.ptMail

        this.currentPt.email = this.ptMail
        const indexOldElement = this.personalTrainers.findIndex(({ id }) => id === this.currentPt.id)
        this.personalTrainers = Object.assign([...this.personalTrainers], { [indexOldElement]: this.currentPt })

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Mail Adresi başarıyla güncellendi.',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updatePtPassword() {
      try {
        await PersonalTrainerAdministration.updatePasswordOfPersonalTrainer({
          personalTrainerMetadataId: this.currentPt.metadataId,
          newPassword: this.password,
        })
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Şifre Başarıyla Güncellendi!',
        })
        this.password = ''
      } catch (e) {
        console.log(e)
      }
    },
    async updateCommissionRate() {
      try {
        await PersonalTrainerAdministration.updateCommissionRateOfPersonalTrainer({
          personalTrainerMetadataId: this.currentPt.metadataId,
          newCommissionRate: this.ptCommissionRate,
        })
        this.ptCommissionRateFromServer = this.ptCommissionRate
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Komisyon Oranı Başarıyla Güncellendi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updatePackageCourseCommissionRate() {
      try {
        await PersonalTrainerAdministration.updatePackageCourseCommissionRateOfPersonalTrainer({
          personalTrainerMetadataId: this.currentPt.metadataId,
          newCommissionRate: this.ptPackageCourseCommissionRate,
        })
        this.ptPackageCourseCommissionRateFromServer = this.ptPackageCourseCommissionRate
        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Komisyon Oranı Başarıyla Güncellendi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updatePtDescription() {
      try {
        await PersonalTrainerAdministration.updateDescriptionOfPersonalTrainer({
          newDescription: this.ptDescription,
          personalTrainerMetadataId: this.currentPt.metadataId,
        })
        this.ptDescriptionFromServer = this.ptDescription

        this.currentPt.description = this.ptDescription
        const indexOldElement = this.personalTrainers.findIndex(({ id }) => id === this.currentPt.id)
        this.personalTrainers = Object.assign([...this.personalTrainers], { [indexOldElement]: this.currentPt })

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Antrenör Açıklaması Güncellendi.',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async updatePtOrder() {
      try {
        await PersonalTrainerAdministration.updatePtOrderOfPersonalTrainer({
          personalTrainerMetadataId: this.currentPt.metadataId,
          ptOrder: this.ptOrder,
        })
        this.ptOrderFromServer = this.ptOrder

        this.currentPt.ptOrder = this.ptOrder
        const indexOldElement = this.personalTrainers.findIndex(({ id }) => id === this.currentPt.id)
        this.personalTrainers = Object.assign([...this.personalTrainers], { [indexOldElement]: this.currentPt })

        notification.success({
          message: 'İşlem Başarılı!',
          description: 'Sıralama Güncellendi.',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async confirmDelete(id) {
      try {
        await PersonalTrainerAdministration.deletePersonalTrainer({
          personalTrainerMetadataId: id,
          reason: 'Admin Panel',
        })

        this.personalTrainers = this.personalTrainers.filter(r => r.metadataId !== id)

        this.editPtVisible = false

        this.ptAvatar = ''
        this.ptFirstName = ''
        this.ptLastName = ''
        this.ptMail = ''
        this.ptMailFromServer = ''
        this.ptCommissionRate = ''
        this.ptCommissionRateFromServer = ''
        this.ptPackageCourseCommissionRate = ''
        this.ptPackageCourseCommissionRateFromServer = ''
        this.currentPtActivities = []
        this.currentPt = {}

        this.editPtVisible = false

        notification.success({
          message: 'Antrenör Başarıyla Silindi!',
        })
      } catch (e) {
        console.log(e)
      }
    },
    async loadPersonalTrainers(page) {
      this.loading = true
      try {
        const { data } = await PersonalTrainerAdministration.listPersonalTrainersForAdministration(page ?? undefined)
        if (data) {
          this.personalTrainers = data.personalTrainers
          this.pagination = data.pagination
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
  },
})
</script>

<style scoped>
.card-max-height {
  height: 738px
}
</style>
